<template>
  <b-card no-body class="p-2">
    <h3 class="mb-2">
      {{
        profileLanguage.perfilIdiomaId == 0
          ? $t("Routes.ProfileLanguageAdd")
          : $t("Routes.ProfileLanguageEdit")
      }}
    </h3>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.profileLanguage.columns.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.profileLanguage.columns.name')"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="usuario"
                  v-model="profileLanguage.perfilId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nombre"
                  :options="profiles"
                  :reduce="(option) => option.perfilId"
                  @search="searchProfiles"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.profileLanguage.columns.language')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.profileLanguage.columns.language')"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="usuario"
                  v-model="profileLanguage.idiomaId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="texto"
                  :options="languages"
                  :reduce="(option) => option.id"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.profileLanguage.columns.percentage')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.profileLanguage.columns.percentage')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input
                  v-model="profileLanguage.porcentaje"
                  type="number"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2 mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner v-if="addingProfileLanguage" small class="mr-1" />
            {{ profileLanguage.perfilIdiomaId == 0 ? $t("Lists.Add") : $t("Lists.Edit") }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BSpinner,
  BFormInvalidFeedback,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import optionService from '@/services/option.service'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import router from '@/router'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,

    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  setup() {
    const profiles = ref([])
    const languages = ref([])
    const addingProfileLanguage = ref(false)
    const profileLanguage = ref({
      perfilIdiomaId: 0,
      perfilId: null,
      idiomaId: null,
      porcentaje: null,
    })

    const blankProfileLanguage = {
      perfilIdiomaId: 0,
      perfilId: null,
      idiomaId: null,
      porcentaje: null,
    }

    const {
      fetchProfileLanguage,
      createProfileLanguage,
      updateProfileLanguage,
      fetchProfiles,
    } = humanResources()

    const {
      fetchOptions,
    } = optionService()

    const resetProfileLanguage = () => {
      profileLanguage.value = JSON.parse(JSON.stringify(blankProfileLanguage))
    }

    if (router.currentRoute.params.perfilIdiomaId) {
      fetchProfileLanguage(router.currentRoute.params.perfilIdiomaId, data => {
        profileLanguage.value = data
      })
    } else {
      profileLanguage.value = JSON.parse(JSON.stringify(blankProfileLanguage))
    }
    const searchProfiles = text => {
      fetchProfiles({ searchQuery: text, limit: 50 }, data => {
        profiles.value = data.perfiles
      })
    }
    fetchProfiles({ limit: 50 }, data => {
      profiles.value = data.perfiles
    })
    fetchOptions({ nombreDominio: 'RecursosHumanosIdioma' }, data => {
      languages.value = data
    })
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetProfileLanguage)

    const onSubmit = () => {
      addingProfileLanguage.value = true
      if (profileLanguage.value.perfilIdiomaId === 0) {
        createProfileLanguage(profileLanguage.value, () => {
          addingProfileLanguage.value = false
          router.push({ name: 'apps-human-resources-profile-language-list' })
        })
          .catch(() => {
            addingProfileLanguage.value = false
          })
      } else {
        updateProfileLanguage(profileLanguage.value.perfilIdiomaId, profileLanguage.value, () => {
          addingProfileLanguage.value = false
          router.push({ name: 'apps-human-resources-profile-language-list' })
        })
      }
    }

    return {
      profiles,
      languages,
      profileLanguage,
      addingProfileLanguage,
      required,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,
      searchProfiles,
    }
  },
}
</script>
